// Only for Server.
const config = {
  REACT_APP_ENDPOINT: window._env_.REACT_APP_ENDPOINT,
  REACT_APP_CAPTCHA: window._env_.REACT_APP_CAPTCHA,
  REACT_APP_SIMPLEACCOUNTS_URL: window._env_.REACT_APP_SIMPLEACCOUNTS_URL,
  REACT_APP_STRIPE_PK: window._env_.REACT_APP_STRIPE_PK
};

// const config = {
//   REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
//   REACT_APP_CAPTCHA: process.env.REACT_APP_CAPTCHA,
//   REACT_APP_SIMPLEACCOUNTS_URL: process.env.REACT_APP_SIMPLEACCOUNTS_URL,
//   REACT_APP_STRIPE_PK: process.env.REACT_APP_STRIPE_PK
// };

export default config;

