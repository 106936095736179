export const numcov=(num,currency)=>{
    let hpp=""
    if(num ){
        if(currency){
            hpp= currency+" " + num.toLocaleString('en-US',{ minimumFractionDigits: 2 }) 
        }
        else {
             hpp= num.toLocaleString('en-US',{ minimumFractionDigits: 2 }) 
        }
    }
    if(num===0){
        hpp= `${currency} 0.00`
    }

  
return hpp
}