import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import {
  Stack,
  TextField,
  Alert,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useUI from "../../hooks/useUI";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import LoadingScreen from "../../components/Apiloading";
import axiosInstance from "../../utils/axios";
import Table from "../../components/Tabels/tabel";
import countryList from "react-select-country-list";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import { useEffect, useMemo, useState } from "react";
import Loading from "src/components/Apiloading";
import Footer from "src/layouts/footer";
import { referralHeadCells } from "src/components/Tabels/tabeldata";

// ----------------------------------------------------------------------

export default function Referral(props) {
  const [userdetail, setuserdetail] = useState({
    address: "",
    lastname: "",
    firstname: "",
    country: "United Arab Emirates",
  });

  const { UIdispatch, UIstate } = useUI();

  const countryoptions = countryList()?.getData();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [view, setview] = useState(false);
  const ProspectSchema = Yup.object().shape({
    address: Yup.string().min(2, "Too Short!").required("address is required"),
    firstname: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastname: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is required"),
    country: Yup.string().required("Please Select Country"),
    phone: Yup.string().required("phone number is required"),
  });

  useEffect(() => {
    getreferral();
  }, []);
  const [refrealdata, setrefraldata] = useState({});
  const getreferral = async () => {
    UIdispatch({ type: "LOADING", payload: true });
    await axiosInstance({
      method: "GET",
      url: `/myreferral`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setrefraldata(res.data);
        UIdispatch({ type: "LOADING", payload: false });

        setview(true);
      })
      .catch((err) => {
        UIdispatch({ type: "LOADING", payload: false });
        enqueueSnackbar(err?.response?.error?.message, { variant: "error" });
      });
  };

  const formik = useFormik({
    initialValues: userdetail,
    enableReinitialize: true,
    validationSchema: ProspectSchema,

    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // callplan(values)
      UIdispatch({ type: "LOADING", payload: true });
      await axiosInstance({
        method: "POST",
        url: `/referrals`,
        headers: {
          "Content-Type": "application/json",
        },
        //  data:values
        data: JSON.stringify({ ...values }),
      })
        .then((res) => {
          UIdispatch({ type: "LOADING", payload: false });
          enqueueSnackbar("Applicaiton Submitted succesfully", {
            variant: "success",
          });
          setrefraldata(res.data);
        })
        .catch((err) => {
          UIdispatch({ type: "LOADING", payload: false });
          enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <LoadingScreen>
      <Box>
        {
          <Box>
            <>
              <Box
                style={{
                  width: "100%",
                  height: "50px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography variant="h4">
                  {refrealdata.status === undefined
                    ? "Apply for Referral Program"
                    : "Your Referral"}
                </Typography>
              </Box>
              {refrealdata.status === "Approved" && (
                <Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Card
                      style={{
                        maxWidth: "fit-content",
                        padding: "20px",
                        marginLeft: "50px",
                      }}
                    >
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {" "}
                            Referral Code
                          </Typography>
                        </Box>

                        <Typography
                          variant="h6"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {" "}
                          {refrealdata.referralcode}
                        </Typography>
                      </Box>
                    </Card>

                    <Card
                      style={{
                        maxWidth: "fit-content",
                        padding: "20px",
                        marginLeft: "50px",
                      }}
                    >
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {" "}
                            Total Earned
                          </Typography>
                        </Box>

                        <Typography
                          variant="h6"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          AED {refrealdata.earned}
                        </Typography>
                      </Box>
                    </Card>

                    <Card
                      style={{
                        maxWidth: "fit-content",
                        padding: "20px",
                        marginLeft: "50px",
                      }}
                    >
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {" "}
                            Commission Rate
                          </Typography>
                        </Box>

                        <Typography
                          variant="h6"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {" "}
                          {refrealdata.commission}%
                        </Typography>
                      </Box>
                    </Card>
                  </Box>

                  <Box p={4}>
                    <Box>
                      <Table
                        rows={refrealdata?.referraltrans?.map(
                          ({ order, id, commision, atrate }) => {
                            return {
                              orderid: id,
                              company: order?.company?.CompanyName,
                              plan: order?.plan?.title,
                              paymentMethod:
                                order?.paymentMethod === "both"
                                  ? "Stripe & wallet"
                                  : order?.paymentMethod,
                              total: `AED ${order?.total}`,
                              amount: `AED ${commision}`,
                              atrate: `${atrate}%`,
                            };
                          }
                        )}
                        headCells={referralHeadCells}
                        type="wallet"
                        pagination={true}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <>
                {refrealdata.status === "Pending" && (
                  <>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Your Application is Pending for approval
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                      <Box
                        sx={{
                          border: "1px solid",
                          padding: 2,
                          maxWidth: 600,
                          textAlign: "center",
                        }}
                      >
                        <strong>
                          Thank you for applying for our referral program! Your application has been successfully submitted.
                          <br /><br />
                          Our team will review your application shortly, and once approved, you will receive a confirmation email along with your unique referral code and details about the commission structure.
                        </strong>
                      </Box>
                    </Box>
                  </>
                )}
              </>


              {refrealdata.status === "Rejected" && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Application has been Rejected
                </Box>
              )}
              {refrealdata.status === undefined && view && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box mt={5} sx={{ maxWidth: { sm: "90%", lg: "60%" } }}>
                    <FormikProvider
                      value={formik}
                      enableReinitialize={true}
                      initialValues={userdetail}
                    >
                      <Form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <Stack spacing={3}>
                          {errors.afterSubmit && (
                            <Alert severity="error">{errors.afterSubmit}</Alert>
                          )}

                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                          >
                            <TextField
                              fullWidth
                              label="First Name"
                              {...getFieldProps("firstname")}
                              error={Boolean(
                                errors.firstname && formik.submitCount > 0
                              )}
                              helperText={
                                formik.submitCount > 0 && errors.firstname
                              }
                            />
                            <TextField
                              fullWidth
                              label="Last Name"
                              {...getFieldProps("lastname")}
                              error={Boolean(
                                errors.lastname && formik.submitCount > 0
                              )}
                              helperText={
                                formik.submitCount > 0 && errors.lastname
                              }
                            />
                          </Stack>

                          <TextField
                            fullWidth
                            type="number"
                            max={10}
                            label="Contact number"
                            {...getFieldProps("phone")}
                            error={Boolean(
                              errors.phone && formik.submitCount > 0
                            )}
                            helperText={formik.submitCount > 0 && errors.phone}
                          />
                          <TextField
                            fullWidth
                            label="Address"
                            multiline
                            maxRows={4}
                            {...getFieldProps("address")}
                            error={Boolean(
                              errors.address && formik.submitCount > 0
                            )}
                            helperText={
                              formik.submitCount > 0 && errors.address
                            }
                          />
                          <FormControl>
                            <InputLabel id="demo-multiple-name-label">
                              Country
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Country"
                              {...getFieldProps("country")}
                              error={Boolean(
                                errors.country && formik.submitCount > 0
                              )}
                              helperText={
                                formik.submitCount > 0 && errors.country
                              }
                            >
                              {/* {countryoptions.map((i) => (
                                <MenuItem value={i.label}>{i.label}</MenuItem>
                              ))} */}
                              <MenuItem selected value={"United Arab Emirates"}>
                                United Arab Emirates
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            Apply Now
                          </LoadingButton>
                        </Stack>
                      </Form>
                    </FormikProvider>
                  </Box>
                </Box>
              )}
              <Footer />
            </>
          </Box>
        }
      </Box>
    </LoadingScreen>
  );
}
