// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import questionMarkCircleFill from '@iconify/icons-eva/question-mark-circle-fill';
// ----------------------------------------------------------------------

const getIcon = (name, icon) => (
  name === "help" ? icon : <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
)

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('wallet'),
  banking: getIcon('note'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('store'),
  analytics: getIcon('subscription'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('company'),
  admin: getIcon('admin'),
  transactions: getIcon('transaction'),
  referral: getIcon('referral'),
  help: getIcon('help', questionMarkCircleFill)
}

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Menu',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Companies', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.booking },
      { title: 'Subscriptions', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'Receipts', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'Wallets', path: PATH_DASHBOARD.general.transactions, icon: ICONS.kanban },

      { title: 'Transactions', path: PATH_DASHBOARD.general.Alltransactions, icon: ICONS.transactions },
      { title: 'Referrals', path: PATH_DASHBOARD.general.referral, icon: ICONS.referral },
      // { title: 'Market Place', path: PATH_DASHBOARD.general.booking, icon: ICONS.ecommerce },
      { title: 'Admin Panel', path: PATH_DASHBOARD.general.admin, icon: ICONS.admin },
      { title: 'Help', path: PATH_DASHBOARD.user.account, icon: ICONS.admin },



    ]
  },

  // MANAGEMENT
  // --------------------------------------------------------------------

  // APP
  // ----------------------------------------------------------------------

];


export default sidebarConfig;
