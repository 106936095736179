/* eslint-disable array-callback-return */

import React, { createContext, useReducer ,useContext, useState, useEffect, } from 'react'
import axiosInstance from '../../utils/axios'
import { reducer, initialState } from './cartreducer'
import useAuth from '../../hooks/useAuth'
import useUI from '../../hooks/useUI'
import { useSnackbar } from 'notistack';
import { numcov } from 'src/constants/numberconvertor';

export const CartContext = createContext()


 const CartContextProvider = ({ children }) => {
  
    const {user}=useAuth()
    const {UIdispatch}=useUI();
    const [cartid,setcartid]=useState(null)
    const [wallet,setwallet]=useState(null)
    const [currency,setcurrency]=useState(null)
    const [transactions,settransactions]=useState([])
    const [state, dispatch] =
        useReducer(reducer, initialState)
        const createcart=async(newstate)=>{
          
               if(user){
                 
                
                 
                   const plaid=[]
                   const addonid=[]
                   newstate.map((item)=>{
                       if(item.type==="subscription")
                       {
                           plaid.push(item?.id)
                       }
                       else if(item.type==="addon")
                       {
                           addonid.push(item?.id)
                       }
                    
                   })
                   
                  await axiosInstance({
                    method:'POST',
                   url:'/usercarts',
                  
                    data:{plans:plaid,addons:addonid}
               
                 
                      }  ).then((res)=>{
                     setcartid(res.data.id)
                    
               
               })
               .catch((error)=>{
                 
                  // UIdispatch({type:'SNACKBAR',payload:{type:'error',message:error?.message,state:true}})
               }
               )
               }
              
           }


        const callcarttoken=async()=>{
        
            if(user){
                
              
                await axiosInstance.get('/usercarts',{
                   
                }).then((res)=>{
                  
                      if(res.data.length > 0){
                        setcartid(res.data[0]?.id)
                     if(state.length===0){
                         dispatch({type:"ADDDATA",payload:[...res.data[0].plans,
                         ...res.data[0].addons]})
                     }
                    } 
                    else {
                      
                        createcart(state)
                    } 
                 
               
               
            })
     .catch((error)=>{
       
    })
            }
          
            
        }





const updatecart=async(newstate)=>{
    if(user){

    
    const plaid=[]
    const addonid=[]
    newstate.map((item)=>{
        if(item.type==="subscription")
        {
            plaid.push(item?.id)
        }
        else if(item.type==="addon")
        {
            addonid.push(item?.id)
        }
     
    })
  
   
   await axiosInstance({
    method:'PUT',
    url:`/usercarts/${cartid}`,
    headers:{
         
           'Content-Type':"application/json"
       },
     data:JSON.stringify({plans:plaid,addons:addonid})
    
   }).then((res)=>{
   
       
  
})
.catch((error)=>{
    console.error(error)

})
    }
   
}

const Emptycart=async()=>{
    dispatch({type:"EMPTY"})
    if(user){
  await axiosInstance({
    method:'PUT',
    url:`/usercarts/${cartid}`,
   
     data:JSON.stringify({plans:null,addons:null})
    
   }).then((res)=>{
 
       
  
})
.catch((error)=>{
    console.error(error)
  
})
    }
   
}
const [newwallet,setnewwallet]=useState("")
const [cartcompany,setcartcompany]=useState()
const getwallet=async()=>{
    if(user){
      
     await axiosInstance({
        method:'GET',
        url:`/wallets`,
        headers:{
              
               'Content-Type':"application/json"
           },
         }).then((res)=>{
       
    
       if(res.data.message) 
        UIdispatch({type:'NOTI',payload:`${numcov(res.data?.balance,res.data?.currency)}`})
       setwallet(res.data?.balance) 
       setcurrency(res.data?.currency)
      settransactions(res.data?.transactions)
    })
    }
 
}

useEffect(()=>{
    if(user) {
        
        callcarttoken()
        getwallet() 
    }
},[user])


        return (
        <CartContext.Provider
            value={{ state, dispatch,callcarttoken,
                updatecart,createcart ,transactions,cartcompany,setcartcompany,
            cartid,Emptycart,getwallet,currency,wallet,setnewwallet,newwallet}}>
            {children}
        </CartContext.Provider>
    )
}
export default CartContextProvider

