/* eslint-disable array-callback-return */

import React, { createContext, useState } from 'react'

export const CurrencyContext = createContext()


 const CurrencyProvider = ({ children }) => {


const [currency,setcurrency]=useState("ADE")


        return (
        <CurrencyContext.Provider
            value={{currency,setcurrency}}>
            {children}
        </CurrencyContext.Provider>
    )
}
export default CurrencyProvider

