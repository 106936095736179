/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from 'react-markdown';
import axiosInstance from "src/utils/axios";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
 "scroll":{
  '&::-webkit-scrollbar': { width: 0, }
 }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PaymentConfirm = (props) => {
  const [pnc,setpnc]=React.useState()
  React.useEffect(()=>{
    const tnc=async ()=>{
      await axiosInstance.get('/privacypolicy').then((res)=>{
          console.log(res.data)
          
          setpnc(res.data.policy)
      })
    }
    tnc()
    
  },[])
  return (
    <div>
      <BootstrapDialog
        onClose={()=>{props.setopen(false)}}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.close}
        >
         
        </BootstrapDialogTitle>
        <DialogContent dividers  minWidth="md" >
        <ReactMarkdown>
       {pnc}
        </ReactMarkdown> 
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              background: "#28d9a",
              margin: "10px",
            }}
            onClick={()=>{props.setopen(false)}}
           
          >
            
           Ok
          </Button>
         
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default PaymentConfirm;
