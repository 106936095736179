export const initialState = []
   

export const reducer = (state, action) => {
    switch (action.type) {
        case 'ADDDATA':
         
            return action.payload
            case 'ADDITEM':
                localStorage.setItem("sacart",[...state,action.payload ])
                return [...state,action.payload ]   
        case "REMOVEITEM":
            localStorage.setItem("sacart",action.payload)
            return action.payload
        case "EMPTY":
                localStorage.setItem("sacart",[])
                return []
        default:
            return state
    }
}