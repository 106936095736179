export const orderRows = [
  {
    ID: 1,
    orderNumber: 5723223,
    orderItem: "Free Subscription",
    orderDate: "2014-04-03",
    orderCompany: " Company Name",
    orderAmount: "AED o.o7o",
    orderStatus: "completed",
    orderInvoice: <a href={`/invoice`}>view invoice</a>,
  },
  {
    ID: 2,
    orderNumber: 8723223,
    orderItem: "Free Subscription",
    orderDate: "2021-04-04",
    orderCompany: " Company Name",
    orderAmount: "AED o.oo3",
    orderStatus: "completed",
    orderInvoice: <a href={`/invoice`}>view invoice</a>,
  },
  {
    ID: 3,
    orderNumber: 7723223,
    orderItem: "Free Subscription",
    orderDate: "2016-04-13",
    orderCompany: " Company Name",
    orderAmount: "AED 10.oo",
    orderStatus: "completed",
    orderInvoice: <a href={`/invoice`}>view invoice</a>,
  },
  {
    ID: 4,
    orderNumber: 87231423,
    orderItem: "Free Subscription",
    orderDate: "2016-04-03",
    orderCompany: " Company Name",
    orderAmount: "AED o.o8o",
    orderStatus: "completed",
    orderInvoice: <a href={`/invoice`}>view invoice</a>,
  },
  {
    ID: 5,
    orderNumber: 872326623,
    orderItem: "Free Subscription",
    orderDate: "2015-04-03",
    orderCompany: " Company Name",
    orderAmount: "AED o9.oo",
    orderStatus: "completed",
    orderInvoice: <a href={`/invoice`}>view invoice</a>,
  },
];
export const orderHeadCells = [
  {
    id: "orderNumber",
    label: "Order Number",
    doSorting: true,
  },

  {
    id: "orderDate",
    label: "Order Date",
    doSorting: true,
  },
  {
    id: "orderCompany",
    label: "Company",
    doSorting: false,
  },
  {
    id: "orderAmount",
    label: "Amount",
    doSorting: false,
  },
  {
    id: "orderStatus",
    label: "Status",
    doSorting: false,
  },
  {
    id: "isPaid",
    label: " Payment Status",
    doSorting: false,
  },
  {
    id: "orderInvoice",
    label: "Order Invoice",
    doSorting: false,
  },
];
export const subRows = [
  {
    ID: 1,
    subName: "SimpleAccounts Chatbot Add-On",
    companyname: "lpha Trading Limited",
    startDate: " 01-05-2021",
    endDate: "31-12-2021",
    renewDate: "25-12-2021",
    subStatus: "Active",
    action: <a href={`/cancel`}>Cancel</a>,
  },
  {
    ID: 2,
    subName: "SimpleAccounts Chatbot Add-On",
    companyname: "lpha Trading Limited",
    startDate: " 01-05-2021",
    endDate: "31-12-2021",
    renewDate: "25-12-2021",
    subStatus: "Active",
    action: <a href={`/cancel`}>Cancel</a>,
  },
  {
    ID: 3,
    subName: "SimpleAccounts Chatbot Add-On",
    companyname: "lpha Trading Limited",
    startDate: " 01-10-2021",
    endDate: "31-12-2021",
    renewDate: "25-12-2021",
    subStatus: "Active",
    action: <a href={`/cancel`}>Cancel</a>,
  },
  {
    ID: 4,
    subName: "SimpleAccounts Chatbot Add-On",
    companyname: "lpha Trading Limited",
    startDate: " 01-05-2021",
    endDate: "20-07-2022",
    renewDate: "25-12-2021",
    subStatus: "Active",
    action: <a href={`/cancel`}>Cancel</a>,
  },
  {
    ID: 5,
    subName: "SimpleAccounts Chatbot Add-On",
    companyname: "lpha Trading Limited",
    startDate: " 01-05-2021",
    endDate: "31-12-2021",
    renewDate: "20-07-2022",
    subStatus: "Active",
    action: <a href={`/cancel`}>Cancel</a>,
  },
];
export const subHeadCells = [
  {
    id: "subName",
    label: "Name",
    doSorting: false,
  },
  {
    id: "companyname",
    label: "Company Name",
    doSorting: false,
  },
  {
    id: "startDate",
    label: "Start Date",
    doSorting: true,
  },
  {
    id: "endDate",
    label: "End Date",
    doSorting: true,
  },
  {
    id: "renewDate",
    label: "Renew Date",
    doSorting: false,
  },
  {
    id: "subStatus",
    label: "Status",
    doSorting: false,
  },
];

export const referralHeadCells = [
  {
    id: "orderid",
    label: "Order Id",
    doSorting: false,
  },
  {
    id: "company",
    label: "Company",
    doSorting: false,
  },
  {
    id: "plan",
    label: "Plan",
    doSorting: false,
  },
  {
    id: "paymentMethod",
    label: "Payment Method",
    doSorting: false,
  },
  {
    id: "atrate",
    label: "Commision Rate",
    doSorting: false,
  },
  {
    id: "amount",
    label: "Commision Earned",
    doSorting: true,
  },
  {
    id: "total",
    label: "Total",
    doSorting: false,
  },
];
export const companyRows = [
  {
    ID: 1,
    companyId: "c9723",
    companyName: "lpha Trading Limited",
    subsctiption: "impleAccounts Business",
    domain: "alph",
    adminemail: "jphn@gmal.com",
    setupDate: "01-01-2021",
    companyStatus: "ctive",
    companyurl: "https://delta29.app.simpleaccounts.io",
    action: <a href={`/manageCompany`}>Manage</a>,
  },
  {
    ID: 2,
    companyId: "c9723",
    companyName: "lpha Trading Limited",
    subsctiption: "impleAccounts Business",
    domain: "alph",
    adminemail: "jphn@gmal.com",
    setupDate: " 01-01-2021",
    companyStatus: "ctive",
    companyurl: "https://delta29.app.simpleaccounts.io",
    action: <a href={`/manageCompany`}>Manage</a>,
  },
  {
    ID: 3,
    companyId: "c9723",
    companyName: "lpha Trading Limited",
    subsctiption: "impleAccounts Business",
    domain: "alph",
    adminemail: "jphn@gmal.com",
    setupDate: " 01-01-2021",
    companyStatus: "ctive",
    companyurl: "https://delta29.app.simpleaccounts.io",
    action: <a href={`/manageCompany`}>Manage</a>,
  },
  {
    ID: 4,
    companyId: "c9723",
    companyName: "lpha Trading Limited",
    subsctiption: "impleAccounts Business",
    domain: "alph",
    adminemail: "jphn@gmal.com",
    setupDate: " 01-01-2021",
    companyStatus: "ctive",
    companyurl: "https://delta29.app.simpleaccounts.io",
    action: <a href={`/manageCompany`}>Manage</a>,
  },
  {
    ID: 5,
    companyId: "c9723",
    companyName: "lpha Trading Limited",
    subsctiption: "impleAccounts Business",
    domain: "alph",
    adminemail: "jphn@gmal.com",
    setupDate: " 01-01-2021",
    companyStatus: "ctive",
    companyurl: "https://delta29.app.simpleaccounts.io",
    action: <a href={`/manageCompany`}>Manage</a>,
  },
];
export const companyHeadCells = [
  {
    id: "companyName",
    label: "Company",
    doSorting: false,
  },
  // {
  //   id: 'companyurl',
  //   label: 'Website',
  //   doSorting:false,
  // },
  {
    id: "active",
    label: "Subscription Status",
    doSorting: false,
  },

  {
    id: "domain",
    label: "Access Your App",
    doSorting: false,
  },
  // {
  //   id: 'checkstatus',
  //   label: 'Check Status',
  //   doSorting:false,
  // },
  {
    id: "version",
    label: "App Version",
    doSorting: false,
  },
  {
    id: "companyStatus",
    label: "App Status",
    doSorting: true,
  },
  /*{
      id: 'actions',
      label: 'Actions',
      doSorting:false,
    },*/
];

export const transHeadCells = [
  {
    id: "id",
    label: "Transaction Id",
    doSorting: false,
  },
  {
    id: "amount",
    label: "Amount",
    doSorting: false,
  },
  {
    id: "createdAt",
    label: "Date",
    doSorting: true,
  },
  {
    id: "type",
    label: "Type",
    doSorting: false,
  },
  {
    id: "balance",
    label: "Balance",
    doSorting: false,
  },
];
export const transHeadCells2 = [
  {
    id: "id",
    label: "Transaction Id",
    doSorting: false,
  },
  {
    id: "amount",
    label: "Amount",
    doSorting: false,
  },
  {
    id: "createdAt",
    label: "Date",
    doSorting: true,
  },
  {
    id: "source",
    label: "source",
    doSorting: false,
  },
  {
    id: "type",
    label: "Type",
    doSorting: false,
  },
  // {
  //   id: 'balance',
  //   label: 'Balance',
  //   doSorting:false,
  // },
];

export const versionHeadCells = [
  {
    id: "id",
    label: "Id",
    doSorting: false,
  },
  {
    id: "frontend",
    label: "Frontend Version",
    doSorting: false,
  },

  {
    id: "backend",
    label: "Backend Version",
    doSorting: false,
  },
  {
    id: "createdAt",
    label: "Date",
    doSorting: true,
  },
  {
    id: "user",
    label: "Update by",
    doSorting: false,
  },
];
