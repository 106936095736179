import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useUI from 'src/hooks/useUI'
import { Box, Typography } from '@mui/material';
import {numcov} from 'src/constants/numberconvertor'
import Confetti from 'react-confetti'


export default function AlertDialog({newwallet,setnewwallet}) {
  const [open, setOpen] = React.useState(false);
const {UIstate, UIdispatch}=useUI();
  const handleClickOpen = () => {
    setOpen(true);
  };
const [w,setw]=React.useState()

  const handleClose = () => {
    UIdispatch({type:"NOTI",payload:null});
   };
   
   const ref = React.useRef(null);
   React.useEffect(()=>{
    if(ref?.current)
      setw(ref.current.getBoundingClientRect().width)
     
   
   },[ref])
  
  return (
    <div>
    
      <Dialog
        open={UIstate.newwallet}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
        style={{overflow: "hidden"}}
      >
 
       
    
        <Box style={{overFlow:'hidden',position: 'relative',maxWidth:'100%' }}>
        <Confetti
      width={600}
      height={250}
      numberOfPieces={100}
    />
          <img src="/static/1.svg" alt={"mask1"} style={{position:'absolute',right:0,bottom:0,}}/>
          <img src="/static/2.svg" alt={"mask1"} style={{position:'absolute',left:0,top:0,}}/>
          <Box ref={ref} style={{overFlow: 'hidden'}} >
            <Box m={3} style={{display:'flex',justifyContent: 'center',alignItems:'center',flexDirection:'column'}}>

           
        <Typography variant={"h2"} color="primary">
          {"Congratulations !!!"}
          </Typography>
            <Box style={{display:'flex',flexDirection: 'column'}} mt={2}>
            <Box style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
            <img src="/static/walletimg.png" alt={"wallet"}/>
           <Typography variant={"h4"} style={{color:'brown',maxWidth:'fit-content',marginLeft:'10px'}}>{numcov(UIstate.newwallet)}</Typography> 
            </Box>
            
           <Typography variant={"subtitle2"}  style={{color:'gray'}}>has been credited to your wallet</Typography> 
            </Box>
          
         
          <Button variant="contained" onClick={handleClose}style={{marginTop:'20px'}} >
            ok
          </Button>
          </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}