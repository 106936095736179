import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { Avatar, Badge } from "@mui/material";
import { ShoppingCartTwoTone } from "@mui/icons-material";
import { useContext } from "react";
import useAuth from "../hooks/useAuth";
import Popper from "./poper";
// ----------------------------------------------------------------------
import { CartContext } from "../ourlogic/Context/cartapi";
import { Box, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  flexDirection: "column",

  justifyContent: "center",
  alignItems: "center",

  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const { state } = useContext(CartContext);
  const { user } = useAuth();
  return (
    <>
      <HeaderStyle>
        <Box
          onClick={() => {
            window.open("https://www.simpleaccounts.io");
          }}
          style={{ cursor: "pointer" }}
        >
          <Logo />
        </Box>
        <Box style={{ display: "flex" }}>
          {/* <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography ml={1} p={1} style={{fontSize:'12px'}}>Privacy&Cookies</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{fontSize:'12px'}}> FAQ</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{fontSize:'12px'}}>Terms&Policy</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{fontSize:'12px'}}>ContactUs</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{fontSize:'12px'}}>Support</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{fontSize:'12px'}}> Feedback</Typography>
        </RouterLink>
        <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/app">
       <Typography  ml={1} p={1} style={{borderRight: '2px solid gray',fontSize:'12px'}}> Language</Typography>
        </RouterLink> */}

          <Typography ml={1} p={1} style={{ fontSize: "12px" }}>
            © 2024 SimpleAccounts. All Rights Reserved
          </Typography>
        </Box>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
