import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import conf from "../constants/config";
// ----------------------------------------------------------------------
const baseurl = conf.REACT_APP_ENDPOINT;

// const axiosInstance = axios.create();
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );
let gettoken;
let currentUser;
export const gettokendata = (func, cu) => {
  gettoken = func;
  currentUser = cu;
};
const axiosInstance = axios.create({
  baseURL: baseurl,
});
axiosInstance.interceptors.request.use(async (config) => {
  const token = Cookies.get("sa-token");
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

export default axiosInstance;
