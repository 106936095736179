import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,Button, Badge, Select, MenuItem } from '@mui/material';
// hooks
import { Link as RouterLink, Outlet } from 'react-router-dom';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { useLocation, useNavigate } from 'react-router';
import { ShoppingCartOutlined, ShoppingCartTwoTone } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from 'src/ourlogic/Context/cartapi';
import useAuth from 'src/hooks/useAuth';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const history=useNavigate()
  const {state,currency,wallet}=useContext(CartContext)
  const {user}=useAuth()
  const getIcon = (name) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
  );
  
  const [applocation,setapplocation]=useState("")
  const location = useLocation()
  useEffect(()=>{
console.log(window.location.href)
if(window.location.href.includes(".ae.")) setapplocation("ae") 
else setapplocation("global")
  },[])


  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        {/*<Select value={applocation} 
      style={{minWidth:100,maxHeight:'30px'}}
      onChange={(e)=>window.location.replace(applocation==="ae"?window.location.href.replace('.ae.','.global.'):window.location.href.replace('.global.','.ae.'))}
      >
    <MenuItem value={"global"}>Global</MenuItem>
    <MenuItem  value={"ae"} >UAE</MenuItem>
</Select>*/}
         <Button  onClick={()=>{history('/compare-Price')}} >Compare Plans</Button>
        
        
          {/* <NotificationsPopover /> */}
          {user !== null &&
        <Badge badgeContent={state.length} color="primary"
         style={{cursor: 'pointer',marginTop:'20px',marginRight:'10px'}}>
        <RouterLink to="/checkout">
          
        <ShoppingCartOutlined size={32} color="secondary" style={{fontSize:35}} />
        </RouterLink>

        </Badge>}

        <RouterLink to="/" style={{width:'50px',height:'50px',display:'flex',justifyContent: 'center',alignItems: 'center'}}>
         <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="25pt" height="25pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#2064D8" stroke="#2064D8">
<path d="M600 4763 l0 -358 -300 -450 -300 -450 0 -370 0 -371 150 -74 150
-74 0 -1158 0 -1158 -150 0 -150 0 0 -150 0 -150 2560 0 2560 0 0 150 0 150
-150 0 -150 0 0 1158 0 1158 150 74 150 74 0 371 0 370 -300 450 -300 450 0
358 0 357 -1960 0 -1960 0 0 -357z m3620 -98 l0 -155 -1660 0 -1660 0 0 155 0
155 1660 0 1660 0 0 -155z m-2784 -472 c-3 -10 -48 -144 -99 -298 l-94 -280
-407 -3 -406 -2 200 300 200 300 306 0 c286 0 305 -1 300 -17z m974 -283 l0
-300 -425 0 c-234 0 -425 1 -425 3 0 3 187 563 195 585 3 9 79 12 330 12 l325
0 0 -300z m1047 15 c51 -154 96 -288 99 -297 5 -17 -19 -18 -420 -18 l-426 0
0 300 0 300 327 -2 326 -3 94 -280z m1033 -15 l200 -300 -406 2 -407 3 -94
280 c-51 154 -96 288 -99 298 -5 16 14 17 300 17 l306 0 200 -300z m-3290
-778 l0 -177 -225 -113 -225 -112 -225 112 -225 113 0 177 0 178 450 0 450 0
0 -178z m1210 0 l0 -177 -228 -114 -227 -113 -228 113 -227 114 0 177 0 178
455 0 455 0 0 -178z m1210 0 l0 -177 -227 -114 -227 -113 -228 113 -228 113 0
178 0 178 455 0 455 0 0 -178z m1200 0 l0 -177 -225 -113 -225 -112 -225 112
-225 113 0 177 0 178 450 0 450 0 0 -178z m-3170 -592 l299 -150 306 152 305
152 306 -152 306 -151 299 149 299 150 300 -150 300 -150 70 35 c38 19 72 35
75 35 3 0 5 -486 5 -1080 l0 -1080 -150 0 -150 0 0 905 0 905 -1660 0 -1660 0
0 -750 0 -750 905 0 905 0 0 -155 0 -155 -1055 0 -1055 0 0 1080 c0 594 2
1080 5 1080 3 0 37 -16 75 -35 l70 -35 297 149 c164 83 299 150 301 150 1 1
137 -66 302 -149z m1060 -1180 l0 -450 -755 0 -755 0 0 450 0 450 755 0 755 0
0 -450z m1210 0 l0 -450 -150 0 -150 0 0 -150 0 -150 150 0 150 0 0 -155 0
-155 -455 0 -455 0 0 755 0 755 455 0 455 0 0 -450z"/>
</g>
</svg>
        </RouterLink>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
