/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useReducer ,useEffect,useContext, useState} from 'react'
import axiosInstance from '../../utils/axios'
import { reducer, initialState } from './companyreducer'
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'



export const CompanyContext = createContext()


 const CompanyContextProvider = ({ children }) => {
    
    const {user}=useAuth()
    const [cartid]=useState(null)
 
    const [cstate, cdispatch] =
        useReducer(reducer, initialState)
        const callcompanytoken=async()=>{
           if(user)
            { 
               
              
                await axiosInstance.get('/companies',{
                   
                }).then((res)=>{
                 
                  
                cdispatch({type:"ADDDATA",payload:res.data?res.data:[]})
                }).catch((error)=>{
                   
                })
            }
            else 
            cdispatch({type:"ADDDATA",payload:[]})
        }



        
const createcompany=async(newstate)=>{
  
  
 
    
    let response = await axiosInstance({
     method:'POST',
    url:'/companies',
    headers:{
          
           'Content-Type':"application/json"
       },
     data:JSON.stringify({...newstate})

  
       }  ).then((res)=>{
        
        res?.data?.id && cdispatch({type:"ADDITEM",payload:res.data})
        return res?.data?.id;

 
})
.catch((error) => {
    console.log("error---", error.response.data);
    return error.response.data?.error?.details;
  });
// .catch((error)=>{
   
//     console.error(error)})
    return response;
}



useEffect(() => {
   
  callcompanytoken()
 console.log("assddadf")
 }, [])

        return (
        <CompanyContext.Provider
            value={{cstate, cdispatch,callcompanytoken,createcompany ,cartid}}>
            {children}
        </CompanyContext.Provider>
    )
}
export default CompanyContextProvider
