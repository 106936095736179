import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
import { useNavigate } from "react-router";
import Modal from "./privacypolicy";
import Modalt from "./tns";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  header: {
    backgroundColor: "white",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const history = useNavigate();

  const handleLoginAuth0 = async () => {
    try {
      await login();
      history("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  const [open, setopen] = useState(false);
  const [openp, setopenp] = useState(false);

  return (
    <RootStyle title="Login | Simpleaccounts">
      <Modal open={openp} setopen={setopenp} />
      <Modalt open={open} setopen={setopen} />
      <AuthLayout>
        Account Not Verified? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={"/auth/resend-verification"}
        >
          Resend Verification Email
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Welcome!
          </Typography>
          <img
            src="/static/illustrations/Login_Page_img.png"
            alt="login"
            height={350}
            width={430}
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to SimpleAccounts
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your details below.
                <br />
              </Typography>
            </Box>
          </Stack>

          {method === "firebase" && <AuthFirebaseSocials />}

          {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@minimals.cc</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}

          {method !== "auth0" ? (
            <LoginForm />
          ) : (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleLoginAuth0}
            >
              Login
            </Button>
          )}
          <Typography
            sx={{
              color: "text.secondary",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            by signing up you are accepting our <br />{" "}
            <Link
              variant="subtitle2"
              component={Button}
              onClick={() => {
                window.open("https://www.simpleaccounts.io/terms/");
              }}
            >
              Terms and Conditions
            </Link>
            &
            <Link
              style={{ marginLeft: "3px" }}
              variant="subtitle2"
              component={Button}
              onClick={() => {
                window.open("https://www.simpleaccounts.io/privacy/");
              }}
            >
              Privacy Policy
            </Link>
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            OR
          </Typography>
          <Link
            to="/auth/signup"
            style={{ marginLeft: "3px", textAlign: "center" }}
            variant="subtitle2"
            component={RouterLink}
          >
            Sign Up
          </Link>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
