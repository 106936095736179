/* eslint-disable react/jsx-no-duplicate-props */
import { Link as RouterLink, Outlet } from 'react-router-dom';
// material

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import {Avatar, Badge, MenuItem, Select, Tooltip} from '@mui/material'
import { ShoppingCart, ShoppingCartOutlined, ShoppingCartTwoTone } from '@mui/icons-material';
import {useContext,useEffect,useState} from 'react'
import useAuth from '../hooks/useAuth';
import Popper from './poper'
// ----------------------------------------------------------------------
import {CartContext} from '../ourlogic/Context/cartapi'
import { Box } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useLocation } from "react-router-dom"
const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
 flexWrap: 'wrap',
 display: 'flex',
 
 justifyContent: 'space-between',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout(props) {
  const {state,currency,wallet}=useContext(CartContext)
  const {user}=useAuth()
  const [applocation,setapplocation]=useState("")
  const location = useLocation()
  useEffect(()=>{
console.log(window.location.href)
if(window.location.href.includes(".ae.")) setapplocation("ae") 
else setapplocation("global")
  },[])

 
  

  return (
    <>
   
      <HeaderStyle>
        <Box onClick={()=>{window.open("https://www.simpleaccounts.io/")}} style={{cursor:"pointer"}}>
          <Logo />
       </Box>
      <Box style={{display: 'flex'}}> 
      {/*<Select value={applocation} 
      style={{minWidth:100,maxHeight:'30px'}}
      onChange={(e)=>window.location.replace(applocation==="ae"?window.location.href.replace('.ae.','.global.'):window.location.href.replace('.global.','.ae.'))}
      >
    <MenuItem value={"global"}>Global</MenuItem>
    <MenuItem  value={"ae"} >UAE</MenuItem>
  </Select>*/}
     {location.pathname==="/"?
      <RouterLink style={{ textDecoration: 'none',marginRight:'10px' }}
      to="/compare-Price">
         <Button  >Compare Plans</Button>
        </RouterLink>
        :<RouterLink style={{ textDecoration: 'none',marginRight:'10px' }}
      to="/">
         <Button  >MarketPlace</Button>
        </RouterLink>
}

      <RouterLink style={{ textDecoration: 'none',marginRight:'10px' }}
      to="/dashboard/app">
        {user !== null && <Button variant="contained">Dashboard</Button>}
        </RouterLink>
       
      
      
        {user !== null &&
        <>  
         <RouterLink style={{ textDecoration: 'none',marginRight:'10px' }}
         to="/dashboard/Transactions"
         >
       
         <Tooltip title={ ((wallet || wallet===0) && currency) 
         &&`${currency} ${wallet}`} 
  
  >
        
         <AccountBalanceWalletIcon size={32} color="secondary"
          style={{fontSize:35}} />
       
        </Tooltip>
        </RouterLink>
        </>
        }

      
        <Badge badgeContent={state.length} color="primary" style={{cursor: 'pointer',}}>
        <RouterLink to="/checkout">
        <ShoppingCartOutlined size={32} color="secondary" style={{fontSize:35}} />
        </RouterLink>
        </Badge>

    
        <RouterLink style={{ textDecoration: 'none',marginRight:'10px' }} to="/auth/login">
        {user === null && <Button variant="contained" style={{marginLeft:'10px'}}>Login / Sign Up</Button>}
        </RouterLink>
       
       
      </Box>
      
      </HeaderStyle>
      <Outlet />
    </>
  );
}
