import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Magic } from "magic-sdk";
import axiosInstance from "src/utils/axios";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { MIconButton } from "src/components/@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from "@iconify/react";
import Cookies from "js-cookie";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tokendetails: null,
  isLoading: true,
};

const handlers = {
  LOADING: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      isLoading: false,
    };
  },
  TOKEN: (state, action) => {
    return {
      ...state,
      tokendetails: action.payload,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "magic",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const magic = new Magic("pk_live_B71FAEA20087BE6E");
  useEffect(() => {
    const gettoken = Cookies.get("sa-token");
    if (gettoken) {
      fetchuser();
    } else {
      dispatch({ type: "LOADING" });
    }
  }, []);

  const fetchuser = async () => {
    await axiosInstance({
      url: "/users/me?populate=*",
      method: "GET",
    }).then((res) => {
      dispatch({ type: "LOGIN", payload: { user: res?.data } });
    });
  };

  const login = async ({ email, password }) => {
    return axiosInstance({
      url: "/auth/local",
      method: "POST",
      data: { identifier: email, password: password },
    }).then((res) => {
      // dispatch({ type: "LOGIN", payload: { user: res?.data?.user } });
      fetchuser();

      // expire in 7days
      Cookies.set("sa-token", res?.data?.jwt, { expires: 7 });
    });
    // try {
    //   await magic.auth.loginWithMagicLink({
    //     email,
    //     showUI: true,
    //   });
    //   const user = await magic.user.getMetadata();
    //   dispatch({ type: "LOGIN", payload: { isAuthenticated: true, user } });
    // } catch (error) {
    //   console.log(error);
    // }
    // await auth0Client.loginWithPopup();
    // const isAuthenticated = await auth0Client.isAuthenticated();
    // if (isAuthenticated) {
    //   const user = await auth0Client.getUser();
    //   dispatch({ type: "LOGIN", payload: { user } });
    // }
  };

  const register = async (data) => {
    return axiosInstance({
      url: "/auth/local/register",
      method: "POST",
      data,
    }).then((res) => {
      //dispatch({ type: "LOGIN", payload: { user: res?.data?.user } });
      //Cookies.set("sa-token", res?.data?.jwt, { expires: 7 });;

      enqueueSnackbar("Confirmation Email has been send to your Email", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    });
  };

  const forgotpassword = async (data) => {
    return axiosInstance({
      url: "/auth/forgot-password",
      method: "POST",
      data,
    }).then((res) => {
      // dispatch({ type: "LOGIN", payload: {user:res?.data?.user}});
      // Cookies.set("sa-token", res?.data?.jwt, { expires: 7 });
    });
  };
  const navigate = useNavigate();
  const resetPassword = async (data) => {
    return axiosInstance({
      url: "/auth/reset-password",
      method: "POST",
      data,
    }).then((res) => {
      dispatch({ type: "LOGIN", payload: { user: res?.data?.user } });
      Cookies.set("sa-token", res?.data?.jwt, { expires: 7 });
      navigate("/dashboard");
    });
  };

  const reverifyemail = async (data) => {
    return axiosInstance({
      url: "/auth/send-email-confirmation",
      method: "POST",
      data,
    }).then((res) => {
      if (res?.data?.sent)
        enqueueSnackbar("Verification Email Send successfully", {
          variant: "success",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
    });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    Cookies.remove("sa-token");
  };

  const gettoken = async () => {
    let resi = "";

    if (state.user) {
      await magic.user
        .getIdToken()
        .then((res) => {
          resi = res;
        })
        .catch((error) => {});
    }
    return resi;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "magic",
        login,
        logout,
        gettoken,
        register,
        forgotpassword,
        resetPassword,
        reverifyemail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
