// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// hooks
import useAuth from "./hooks/useAuth";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeLocalization from "./components/ThemeLocalization";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import { gettokendata } from "./utils/axios";
import { useEffect } from "react";
import Modalimpo from "src/pages/dashboard/conraulationmodal";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// ----------------------------------------------------------------------
import CompanyProvider from "./ourlogic/Context/companycontext";
import CartProvider from "./ourlogic/Context/cartapi";
import UIContextProvider from "./ourlogic/Context/UIcontextapi";
import CurrencyProvider from "./ourlogic/Context/currencyapi";
import { AuthProvider } from "./ourlogic/Context/MagicContext";

export default function App() {
  const { isInitialized } = useAuth();
  const { gettoken, user } = useAuth();
  useEffect(() => {
    gettokendata(gettoken, user);
    const elelment = document.getElementById("root");
    elelment.style.overflowX = "overlay";
  }, [user]);
  const queryClient = new QueryClient();
  return (
    <ThemeConfig>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemePrimaryColor>
          <ThemeLocalization>
            <NotistackProvider>
              <UIContextProvider>
                <AuthProvider>
                  <CompanyProvider>
                    <CartProvider>
                      <CurrencyProvider>
                        <RtlLayout>
                          <Modalimpo />
                          <GlobalStyles />
                          <ProgressBarStyle />
                          <BaseOptionChartStyle />
                          <ScrollToTop />
                          <GoogleAnalytics />
                          {<Router />}
                        </RtlLayout>
                      </CurrencyProvider>
                    </CartProvider>
                  </CompanyProvider>
                </AuthProvider>
              </UIContextProvider>
            </NotistackProvider>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </QueryClientProvider>
    </ThemeConfig>
  );
}
